<template>
  <b-card
    v-if="kits"
    title="Kits / Fundas"
    class="p-2"
  >
    <b-row>
      <b-col
        class="d-flex flex-column flex-wrap text-center"
      >
        <h1 class="font-large-2 font-weight-bolder mt-2 mb-0 text-primary">
          {{ kits.kits.kits_amount - kits.kits.outgoing_kits }}
        </h1>
        <small>Kits disponibles</small>

        <h2 class="font-large-1 font-weight-bolder mt-2 mb-0">
          {{ kits.kits.outgoing_kits }}
        </h2>
        <small>Kits Entregados</small>
        <small class="text-warning">Ingresados: {{ kits.kits.kits_amount }}</small>

        <b-button
          class="ml-1 mt-2"
          variant="primary"
          @click="openModalUpdateKits(kits.kits)"
        >
          <span class="text-nowrap">Actualizar kits</span>
        </b-button>

      </b-col>

      <b-col
        class="d-flex flex-column flex-wrap text-center"
      >
        <h1 class="font-large-2 font-weight-bolder mt-2 mb-0 text-info">
          {{ kits.neo_cases.kits_amount - kits.neo_cases.outgoing_kits }}
        </h1>
        <small>Fundas NEO disponibles</small>

        <h2 class="font-large-1 font-weight-bolder mt-2 mb-0">
          {{ kits.neo_cases.outgoing_kits }}
        </h2>
        <small>Fundas NEO entregadas</small>
        <small class="text-warning">Ingresados: {{ kits.neo_cases.kits_amount }}</small>

        <b-button
          class="ml-1 mt-2"
          variant="info"
          @click="openModalUpdateKits(kits.neo_cases)"
        >
          <span class="text-nowrap">Actualizar fundas</span>
        </b-button>
      </b-col>

      <b-col
        class="d-flex flex-column flex-wrap text-center"
      >
        <h1 class="font-large-2 font-weight-bolder mt-2 mb-0 text-info">
          {{ kits.plus_cases.kits_amount - kits.plus_cases.outgoing_kits }}
        </h1>
        <small>Fundas PLUS disponibles</small>

        <h2 class="font-large-1 font-weight-bolder mt-2 mb-0">
          {{ kits.plus_cases.outgoing_kits }}
        </h2>
        <small>Fundas PLUS entregadas</small>
        <small class="text-warning">Ingresados: {{ kits.plus_cases.kits_amount }}</small>

        <b-button
          class="ml-1 mt-2"
          variant="info"
          @click="openModalUpdateKits(kits.plus_cases)"
        >
          <span class="text-nowrap">Actualizar fundas</span>
        </b-button>
      </b-col>

      <b-col
        class="d-flex flex-column flex-wrap text-center"
      >
        <h1 class="font-large-2 font-weight-bolder mt-2 mb-0 text-info">
          {{ kits.smart_cases.kits_amount - kits.smart_cases.outgoing_kits }}
        </h1>
        <small>Fundas SMART disponibles</small>

        <h2 class="font-large-1 font-weight-bolder mt-2 mb-0">
          {{ kits.smart_cases.outgoing_kits }}
        </h2>
        <small>Fundas SMART entregadas</small>
        <small class="text-warning">Ingresados: {{ kits.smart_cases.kits_amount }}</small>

        <b-button
          v-show="!loading"
          class="ml-1 mt-2"
          variant="info"
          @click="openModalUpdateKits(kits.smart_cases)"
        >
          <span class="text-nowrap">Actualizar fundas</span>
        </b-button>
      </b-col>

    </b-row>

    <b-modal
        v-model="modalUpdateKits"
        id="modal-login"
        cancel-variant="outline-secondary"
        centered
        title="Agregar kits"
        hide-footer
    >

      <b-card-text>
        Recuerda que la cantidad que vas a ingresar se va a sumar a la cantidad disponible en la plataforma
      </b-card-text>

      <validation-observer ref="editKitsValidation">

        <b-form
          class="mt-2" 
          @submit.prevent="editKits">

          <b-form-group>
            <label for="amount">Cantidad a agregar:</label>
            <validation-provider
                #default="{ errors }"
                name="Cantidad a agregar"
                rules="required"
            >
              <b-form-input
                  v-model="amount"
                  id="amount"
                  type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <b-spinner
              variant="primary"
              class="mt-4 mb-2 float-right"
              v-show="loading"></b-spinner>

          <!-- submit button -->
          <b-button
              variant="primary"
              type="submit"
              size="md"
              class="mt-2 mb-2 float-right"
              v-show="!loading"
          >
            Actualizar
          </b-button>

        </b-form>
      </validation-observer>
    </b-modal>

  </b-card>

  
</template>

<script>
import {
  BCard, BRow, BCol, BCardText, BButton, BModal, BFormInput, BForm, BFormGroup, BSpinner, 
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import axios from 'axios'
import {constants} from "@/helpers/constants"
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      modalUpdateKits: false,
      locale: 'es',
      kits: null,
      required,
      loading: false,
      amount: null,
      kitEdit: null,
    }
  },
  created() {
    localize(this.locale)

    this.getKits()
  },
  methods: {
    openModalUpdateKits(kit) {
      this.kitEdit = kit
      this.modalUpdateKits = true
    },
    getKits () {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/kits', { headers }).then(response => {
        console.log('kits: ', response)

        this.kits = response.data.data
        
      }).catch( err => {
        console.log(err)
      })
    },
    editKits() {
      event.preventDefault()
      this.$refs.editKitsValidation.validate().then(success => {
        if (success) {
          this.loading = true

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/kits/' + this.kitEdit.id, {
            kits_amount: this.amount,
          }, { headers }).then(response => {
            console.log('kits: ', response)
            this.loading = false

            if(response.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })

              this.modalUpdateKits = false
              this.amount = null
              this.getKits()

            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }

          }).catch( err => {
            console.log(err)
          })

        }
      })
    }
  }
}
</script>
