<template>
  <b-row>
    <b-col cols="12">
      <kits-card />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import KitsCard from './KitsCard.vue'

export default {
  components: {
    BRow,
    BCol,

    KitsCard
  },
}
</script>

<style lang="scss" >
</style>